import React, { useState, useRef, useEffect } from 'react';
import { useChat } from '../hooks/useChat';
import { Html } from '@react-three/drei';

const MessageList = () => {
  const { message, playMessage, currentMessage, stopMessage } = useChat();
  const [messages, setMessages] = useState([]);
  const [displayedMessages, setDisplayedMessages] = useState([]); // Messages that have finished animating
  const [displayedMessage, setDisplayedMessage] = useState(''); // The message currently animating
  const containerRef = useRef(null);

  useEffect(() => {
    if (message && message.text) {
      setMessages((prevMessages) => [...prevMessages, message]);
    }
  }, [message]);

  useEffect(() => {
    if (messages.length > displayedMessages.length) {
      const currentMessage = messages[displayedMessages.length];
      let index = 0;

      // Reset displayedMessage at the start of displaying a new message
      setDisplayedMessage('');

      const intervalId = setInterval(() => {
        if (index < currentMessage.text.length) {
          setDisplayedMessage(currentMessage.text.slice(0, index + 1)); // Show text up to the current index
          index++;
        } else {
          clearInterval(intervalId);
          setDisplayedMessages((prev) => [...prev, currentMessage]); // Add completed message to displayedMessages
          setDisplayedMessage(''); // Reset displayedMessage for the next message
        }
      }, 50); // Typing speed

      return () => clearInterval(intervalId);
    }
  }, [messages, displayedMessages]);

  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
    }
  }, [displayedMessages, displayedMessage]);

  return (
    <Html position={[0.3, -0.05, -4]} transform distanceFactor={0.5}>
      <div
        className="w-[1288px] h-[676px] p-8 overflow-y-auto bg-transparent opacity-80"
        ref={containerRef}
      >
        {messages.length === 0 ? (
          <div className="h-full w-full grid place-content-center text-center">
            <h2 className="text-8xl font-bold text-white/90 italic">
              School of
              <br />
              wallPiller
            </h2>
            <h2 className="text-8xl font-bold font-jp text-red-600/90 italic">
              {/* वॉलपिलर हिंदी भाषा स्कूल */}
            </h2>
          </div>
        ) : (
          <div className="flex flex-col space-y-4">
            {displayedMessages.map((msg, index) => (
              <div key={index} className="flex items-center justify-between py-2">
                <h2 className="text-6xl font-bold text-white/90 italic">
                  {msg.text}
                </h2>
                {currentMessage === msg ? (
                  <button
                    className="text-white/65"
                    onClick={() => stopMessage(msg)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-12 h-12"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 0 1 9 14.437V9.564Z"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    className="text-white/65"
                    onClick={() => playMessage(msg)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-12 h-12"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
                      />
                    </svg>
                  </button>
                )}
              </div>
            ))}
            {displayedMessage && (
              <div className="text-center py-2">
                <h2 className="text-6xl font-bold text-white/90 italic">
                  {displayedMessage}
                </h2>
              </div>
            )}
          </div>
        )}
      </div>
    </Html>
  );
};

export default MessageList;
