import React, { useState, useRef, useEffect } from 'react';
import { useChat } from '../hooks/useChat';
import { Html } from '@react-three/drei';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';  // Importing the spinning icon
import PdfDownloadButton from './PdfDownloadButton.jsx'
import "../globals.css";

const questionsEnsure = [
  { id: 1, question: "Can you write the first four values in an arithmetic sequence ?", correctAnswer: 14 },
  { id: 2, question: "Can you write the first four values in a non-arithmetic sequence?", correctAnswer: 30 },
];
const questions = [
  { id: 1, question: "1. What is the 5th term of the sequence: 2, 5, 8, 11, ...?", correctAnswer: 14 },
  { id: 2, question: "2. What is the 10th term of the sequence: 3, 6, 9, 12, ...?", correctAnswer: 30 },
  { id: 3, question: "3. If the first term of an arithmetic sequence is 7 and the common difference is 4, what is the 6th term?", correctAnswer: 27 },
  { id: 4, question: "4. What is the common difference in the sequence: -3, 1, 5, 9, ...?", correctAnswer: 4 }
];
const questionsone = [
  { id: 1, question: "1, 4, 9, 16, 25, ... is the difference between two neighboring terms the same in this list?", correctAnswer: 'No' },
  { id: 2, question: "Here 2, 3, 4... is the difference between two neighboring terms the same in this list?", correctAnswer: 'Yes' }
]; const questionsbeforeeq = [
  { id: 1, question: "1, 4, 7, 10, 13 ... Write the next value or 6th term?", correctAnswer: 16 },
  { id: 2, question: "1, 4, 7, 10, 13 ...Write the 10th term?", correctAnswer: 28 }
];


const StMessListTM = ({ profileData, selectedSubject, selectedChapter, selectedSubtopics, text }) => {
  const { message, sendPercentageToBackend, textPaused, studentchatui, loading, studentevaluation } = useChat();
  const [messages, setMessages] = useState([]);
  const [doubtResponse, setDoubtResponse] = useState(null);
  const [displayedText, setDisplayedText] = useState({}); // Holds the currently animated text for each part
  const containerRef = useRef(null);

  const [feedback, setFeedback] = useState(Array(4).fill(""));
  const [showQuestions, setShowQuestions] = useState(false);
  const [showQuestionsbeforeEq, setshowQuestionsbeforeEq] = useState(false);
  const [showQuestionsEnsureQns, setshowQuestionsEnsureQns] = useState(false);

  const [inputAnswers, setInputAnswers] = useState(Array(4).fill("")); // State to track input values
  const totalQuestions = questions.length; // Total number of questions (4 in this case)
  const [showQuestionsOne1, setShowQuestionsOne1] = useState(false);  // State to track button click
  const [showQuestionsOne, setShowQuestionsOne] = useState(false);  // State to track button click
  //loadings
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingqns, setShowLoadingqns] = useState(false);
  const [showLoadingscore, setShowLoadingscore] = useState(false);
  const [showLoadingBeforeEq2, setshowLoadingBeforeEq2] = useState(false);
  const [showLoadingfinish, setShowLoadingfinish] = useState(false);

  console.log("message from list", message);

  // Define countdown states for both sections
  const [countdownIntroQuestion, setCountdownIntroQuestion] = useState(15);
  const [countdownDefinition, setCountdownDefinition] = useState(15);
  const [countdownCommoDifference, setCountdownCommoDifference] = useState(15);
  const [countdownExample, setCountdownExample] = useState(15);
  const [countdownFormula, setCountdownFormula] = useState(15);

  const [isIntroQuestionAnimationComplete, setIsIntroQuestionAnimationComplete] = useState(false);
  const [isIntroQuestion2AnimationComplete, setIsIntroQuestion2AnimationComplete] = useState(false);
  const [isDefinitionAnimationComplete, setIsDefinitionAnimationComplete] = useState(false);
  const [isExampleAnimationComplete, setIsExampleAnimationComplete] = useState(false);
  const [isFormulaAnimationComplete, setIsFormulaAnimationComplete] = useState(false);
  const [isQuestionForYouComplete, setisQuestionForYouComplete] = useState(false);
  const [isExampleProblem, setExampleProblem] = useState(false);
  const [isImportance, setisImportance] = useState(false);
  const [isBeforeEq, setisBeforeEq] = useState(false);
  const [isSummary, setisSummary] = useState(false);
  const [isCommonDifference, setisCommonDifference] = useState(false);
  const [isEnsureQn, setisEnsureQn] = useState(false);
  const [isConclusion, setisConclusion] = useState(false);

  const [showNextevaluationsecondOne, setshowNextevaluationsecondOne] = useState(false);
  const [showNextevaluationsecondTwo, setshowNextevaluationsecondTwo] = useState(false);
  const [showNextevaluationsecondThree, setshowNextevaluationsecondThree] = useState(false);
  const [showNextevaluationsecondFour, setshowNextevaluationsecondFour] = useState(false);
  const [showNextevaluationEnsuretwo, setshowNextevaluationEnsuretwo] = useState(false);

  const [shownextExampleProblem, setshownextExampleProblem] = useState(false);
  const [shownextnextSteptwo, setshownextnextSteptwo] = useState(false);
  const [shownviewEquation, setshownviewEquation] = useState(false);


  const [d, setD] = useState("");       // For user input of d
  const [X1, setX1] = useState("");     // For user input of X1
  const [feedback1, setFeedback1] = useState(""); // For feedback on d
  const [feedback2, setFeedback2] = useState(""); // For feedback on X1
  const [answer1, setAnswer1] = useState("");
  const [feedback3, setFeedback3] = useState("");

  const [isVerifyDisabled, setIsVerifyDisabled] = useState(false);

  const verifyD = () => {
    if (parseInt(d) === 3) {
      setFeedback1("Correct!");
    } else {
      setFeedback1("Incorrect. The correct value of d is 3.");
    }
    // Disable the button after clicking
    setIsVerifyDisabled(true);
  };


  const [isVerifyX1Disabled, setIsVerifyX1Disabled] = useState(false);

  const verifyX1 = () => {
    if (parseInt(X1) === 1) {
      setFeedback2("Correct!");
    } else {
      setFeedback2("Incorrect. The correct value of X1 is 1.");
    }

    // Disable the button after clicking
    setIsVerifyX1Disabled(true);
  };
  const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(false);

  const verifyAnswer = () => {
    setIsVerifyButtonDisabled(true);

    if (parseInt(answer1, 10) === 22) {
      setFeedback3("Correct!");
    } else {
      setFeedback3("Incorrect! Try again.");
    }
  };


  useEffect(() => {
    if (message && message.text && !textPaused) {
      console.log("Message Content:", message.text.evaluationfirstone);
      console.log("Message Content:", message.text.evaluationfirsttwo);

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          content: message.text,
          correctAnswer: message.answer,
          questionbknd: message.question
        }
      ]);
    }
  }, [message, textPaused]);
  const [isDisabledEnsureEq1, setIsDisabledEnsureEq1] = useState(false);
  const [isDisabledEnsureEq2, setIsDisabledEnsureEq2] = useState(false);

  //Ensure qns after example
  const handleSubmitEnsureeq1 = () => {
    setIsDisabledEnsureEq1(true); // Disable button
    // Get the correct answer for question 1
    const correctAnswer = questionsEnsure[0].correctAnswer;
    const text = 'ensureeq1'
    // Determine if the answer is correct or incorrect
    const feedback = parseInt(answer) === correctAnswer ? "correct" : "incorrect";
    console.log("ensureeq1 :", feedback)
    setShowLoadingqns(true);
    // Call studentEvaluation function with necessary arguments
    studentevaluation(answer, questionsEnsure[0].question, feedback, text);
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const handleSubmitEnsureeq2 = () => {
    setIsDisabledEnsureEq2(true); // Disable button
    // Get the correct answer for question 1
    const correctAnswer = questionsEnsure[1].correctAnswer;
    const text = 'ensureeq2'
    // Determine if the answer is correct or incorrect
    const feedback = parseInt(answer) === correctAnswer ? "correct" : "incorrect";
    console.log("ensureeq2 :", feedback)
    setshowLoadingBeforeEq2(true);
    // Call studentEvaluation function with necessary arguments
    studentevaluation(answer, questionsEnsure[1].question, feedback, text);
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const [ensureEq1, seteEnsureEq1] = useState("");
  useEffect(() => {
    if (message?.text?.ensureeq1) {
      seteEnsureEq1(message.text.ensureeq1);
    }
  }, [message?.text?.ensureeq1]);
  const [ensureEq2, seteEnsureEq12] = useState("");
  useEffect(() => {
    if (message?.text?.ensureeq2) {
      seteEnsureEq12(message.text.ensureeq2);
    }
  }, [message?.text?.ensureeq2]);


  const [isDisabledEnsureEq3, setIsDisabledEnsureEq3] = useState(false);
  const [isDisabledEnsureEq4, setIsDisabledEnsureEq4] = useState(false);

  //Before Equation Qns
  const handleSubmitbeforeq1 = () => {
    setIsDisabledEnsureEq3(true)
    // Get the correct answer for question 1
    const correctAnswer = questionsbeforeeq[0].correctAnswer;
    const text = 'beforeeq1'
    // Determine if the answer is correct or incorrect
    const feedback = parseInt(answer) === correctAnswer ? "correct" : "incorrect";
    console.log("beforeeq1 :", feedback)
    setShowLoadingqns(true);
    // Call studentEvaluation function with necessary arguments
    studentevaluation(answer, questionsbeforeeq[0].question, feedback, text);
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const handleSubmitbeforeq2 = () => {
    setIsDisabledEnsureEq4(true)

    // Get the correct answer for question 1
    const correctAnswer = questionsbeforeeq[1].correctAnswer;
    const text = 'beforeeq2'
    // Determine if the answer is correct or incorrect
    const feedback = parseInt(answer) === correctAnswer ? "correct" : "incorrect";
    setshowLoadingBeforeEq2(true);
    console.log("beforeeq1 :", feedback)
    // Call studentEvaluation function with necessary arguments
    studentevaluation(answer, questionsbeforeeq[1].question, feedback, text);
    // Optionally, clear the answer for the next question
    setAnswer("");

  };

  const [beforeEq1, setBeforeEq1] = useState("");
  useEffect(() => {
    if (message?.text?.beforeeq1) {
      setBeforeEq1(message.text.beforeeq1);
    }
  }, [message?.text?.beforeeq1]);
  const [beforeEq2, setbeforeeEq2] = useState("");
  useEffect(() => {
    if (message?.text?.beforeeq2) {
      setbeforeeEq2(message.text.beforeeq2);
    }
  }, [message?.text?.beforeeq2]);

  //Evaluation second full useState and useEffect
  const [answer, setAnswer] = useState("");
  const [score, setScore] = useState(0);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [isScoreVisible, setIsScoreVisible] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleSubmitone = () => {
    setIsSubmitDisabled(true);

    // Get the correct answer for question 1
    const correctAnswer = questions[0].correctAnswer;
    const text = 'secondone'
    // Determine if the answer is correct or incorrect
    const feedback = parseInt(answer) === correctAnswer ? "Correct" : "Incorrect";
    setShowLoadingscore(true);

    // Update the score if the answer is correct
    if (feedback === "Correct") setScore((prev) => prev + 1);
    // Call studentEvaluation function with necessary arguments
    studentevaluation(answer, questions[0].question, feedback, text);
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const [isSubmitDisabled2, setIsSubmitDisabled2] = useState(false);
  const handleSubmittwo = () => {
    setIsSubmitDisabled2(true);

    const correctAnswer = questions[1].correctAnswer;
    const feedback = parseInt(answer) === correctAnswer ? "Correct" : "Incorrect";
    setShowLoadingqns(true);

    // Update the score if the answer is correct
    if (feedback === "Correct") setScore((prev) => prev + 1);
    studentevaluation(answer, questions[1].question, feedback, 'secondtwo');
    setAnswer("");
  };
  const [isSubmitDisabled3, setIsSubmitDisabled3] = useState(false);

  const handleSubmitthree = () => {
    setIsSubmitDisabled3(true);

    const correctAnswer = questions[2].correctAnswer;
    const feedback = parseInt(answer) === correctAnswer ? "Correct" : "Incorrect";
    setShowLoadingscore(true);

    // Update the score if the answer is correct
    if (feedback === "Correct") setScore((prev) => prev + 1);
    studentevaluation(answer, questions[2].question, feedback, 'secondthree');
    setAnswer("");
  };

  const [isSubmitDisabled4, setIsSubmitDisabled4] = useState(false);

  const handleSubmitfour = () => {
    setIsSubmitDisabled4(true);

    const correctAnswer = questions[3].correctAnswer;
    const feedback = parseInt(answer) === correctAnswer ? "Correct" : "Incorrect";
    setShowLoadingqns(true);

    // Update the score if the answer is correct
    if (feedback === "Correct") setScore((prev) => prev + 1);
    studentevaluation(answer, questions[3].question, feedback, 'secondfour');
    setAnswer("");

    // Mark all questions as answered
    setAllQuestionsAnswered(true);
  };

  const [evaluationSecondone, setEvaluationSecondone] = useState("");
  const [evaluationSecondtwo, setEvaluationSecondtwo] = useState("");
  const [evaluationSecondthree, setEvaluationSecondthree] = useState("");
  const [evaluationSecondfour, setEvaluationSecondfour] = useState("");


  useEffect(() => {
    if (message?.text?.evaluationsecondone) {
      setEvaluationSecondone(message.text.evaluationsecondone);
    }
  }, [message?.text?.evaluationsecondone]);

  useEffect(() => {
    if (message?.text?.evaluationsecondtwo) {
      setEvaluationSecondtwo(message.text.evaluationsecondtwo);
    }
  }, [message?.text?.evaluationsecondtwo]);

  useEffect(() => {
    if (message?.text?.evaluationsecondthree) {
      setEvaluationSecondthree(message.text.evaluationsecondthree);
    }
  }, [message?.text?.evaluationsecondthree]);

  useEffect(() => {
    if (message?.text?.evaluationsecondfour) {
      setEvaluationSecondfour(message.text.evaluationsecondfour);
    }
  }, [message?.text?.evaluationsecondfour]);

  const nextEvaluationSecondone = () => {
    setshowNextevaluationsecondOne(true);
  };
  const nextEvaluationSecondtwo = () => {
    setshowNextevaluationsecondTwo(true);
  };
  const nextEvaluationSecondthree = () => {
    setshowNextevaluationsecondThree(true);
  };
  const nextEvaluationSecondfour = () => {
    setshowNextevaluationsecondFour(true);
  };
  const nextEvaluationEnsuretwo = () => {
    setshowNextevaluationEnsuretwo(true);
  };

  //finished the Evaluation second JS


  const [isViewEquationDisabled, setIsViewEquationDisabled] = useState(false);

  const nextViewEquation = () => {
    // Disable the button
    setIsViewEquationDisabled(true);

    // Trigger the equation view functionality
    handleViewEquation(true);
    setshownviewEquation(true);
  };

  const [isAnswerButtonDisabled, setIsAnswerButtonDisabled] = useState(false);

  const nextExampleProblem = () => {
    // Disable the button after it's clicked
    setIsAnswerButtonDisabled(true);

    // Call the functions as needed
    handlenextstep1(true);
    setshownextExampleProblem(true);
  };

  const [isStepTwoDisabled, setIsStepTwoDisabled] = useState(false);

  const nextSteptwo = () => {
    handlenextstep2(true);
    setshownextnextSteptwo(true);

    // Disable the button after it's clicked
    setIsStepTwoDisabled(true);
  };

  const handlenextstep1 = () => {
    // Get the correct answer for question 1
    setShowLoadingqns(true);
    // Call studentevaluationmalayalam function with necessary arguments
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'nextstep1');
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const handlenextstep2 = () => {
    // Get the correct answer for question 1
    setshowLoadingBeforeEq2(true);
    // Call studentevaluationmalayalam function with necessary arguments
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'nextstep2');
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const handleViewEquation = () => {
    // Get the correct answer for question 1
    setShowLoadingqns(true);
    // Call studentevaluationmalayalam function with necessary arguments
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'viewequation');
    // Optionally, clear the answer for the next question
    setAnswer("");

  };
  const [isFinishButtonDisabled3, setIsFinishButtonDisabled3] = useState(false);

  const handleexampleProblem = () => {
    // Get the correct answer for question 1
    setShowLoading(true);
    setIsFinishButtonDisabled3(true)
    // Call studentevaluationmalayalam function with necessary arguments
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'problem');
    // Optionally, clear the answer for the next question
    setAnswer("");

  };



  //typewrite Animation
  // Function to trigger typewriter animation for a specific text part
  const animateText = (fullText, key) => {
    let index = 0;
    setDisplayedText((prev) => ({ ...prev, [key]: '' })); // Reset the text part

    // Set speed conditionally
    const speed =
      (key === "importance") ? 105 : // Speed 110 for "importance" and "example"
        (key === "example") ? 90 : // Speed 110 for "importance" and "example"
          (key === "commondifference" || key === "example_problem" || key === "formula_combined") ? 100 : // Speed 100 for these keys
            70; // Default speed

    const intervalId = setInterval(() => {
      if (index < fullText.length) {
        setDisplayedText((prev) => ({
          ...prev,
          [key]: fullText.slice(0, index + 1),
        }));
        index++;
      } else {
        clearInterval(intervalId);
        // Completion handlers for each key
        if (key === "intro_question_1") {
          setIsIntroQuestionAnimationComplete(true);
        }
        if (key === "intro_question_2") {
          setIsIntroQuestion2AnimationComplete(true);
        }
        if (key === "definition") {
          setIsDefinitionAnimationComplete(true);
        }
        if (key === "example") {
          setIsExampleAnimationComplete(true);
        }
        if (key === "formula_combined") {
          setIsFormulaAnimationComplete(true);
        }
        if (key === "questions_for_you_to_solve") {
          setisQuestionForYouComplete(true);
        }
        if (key === "example_problem") {
          setExampleProblem(true);
        }
        if (key === "importance") {
          setisImportance(true);
        }
        if (key === "beforeeq") {
          setisBeforeEq(true);
        }
        if (key === "summary") {
          setisSummary(true);
        }
        if (key === "commondifference") {
          setisCommonDifference(true);
        }
        if (key === "ensureqns") {
          setisEnsureQn(true);
        }
        if (key === "conclusion") {
          setisConclusion(true);
        }
      }
    }, speed); // Use the conditional speed
  };

  // Trigger animations sequentially for all text parts in a message
  const startAnimationForMessage = (messageContent) => {
    const textParts = [
      "greeting",
      "intro_question_1",
      "intro_question_2",
      "definition",
      "example",
      "importance",
      "formula_combined",
      "example_problem",
      "evaluation",
      "questions_for_you_to_solve",
      "conclusion",
      "intraction",
      "beforeeq",
      "summary",
      "commondifference",
      "ensureqns"
    ];

    textParts.reduce((promise, part) => {
      return promise.then(() => {
        if (messageContent[part]) {
          animateText(messageContent[part], part);
          return new Promise((resolve) => setTimeout(resolve, messageContent[part].length * 50 + 500)); // Add delay for typing
        }
        return Promise.resolve();
      });
    }, Promise.resolve());
  };

  useEffect(() => {
    if (messages.length > 0) {
      const latestMessage = messages[messages.length - 1];
      startAnimationForMessage(latestMessage.content);
    }
  }, [messages]);

  //Scorlling
  useEffect(() => {
    if (containerRef.current && !textPaused) {
      setTimeout(() => {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 10);
    }
  }, [messages, textPaused, displayedText, showQuestionsOne1, showQuestionsOne, showQuestions, showQuestionsbeforeEq, showQuestionsEnsureQns, showLoading, showLoadingqns, showLoadingBeforeEq2, showLoadingfinish, showNextevaluationsecondOne, showNextevaluationsecondTwo, showNextevaluationEnsuretwo, showNextevaluationsecondThree,
    isExampleAnimationComplete, isEnsureQn, isScoreVisible,isQuestionForYouComplete, isConclusion, isExampleProblem, isFormulaAnimationComplete, isBeforeEq, isDefinitionAnimationComplete, isIntroQuestionAnimationComplete, isIntroQuestion2AnimationComplete, isCommonDifference
  ]);



  const renderWithLineBreaks = (text) => {
    return text.split("\n").map((line, index) => (
      <p key={index} className="mb-2">
        {line}
      </p>
    ));
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleDoubtResponseformula = (response) => {

    if (response === 'Yes') {
      // Trigger loading spinner after clicking "Yes"
      setShowLoading(true);
      setIsButtonDisabled(true);

      studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'formula');
    } else {
      setDoubtResponse("To ask a question, type below or click the voice button.");
      alert("To ask a question, type below or click the voice button.");
    }
  };

  const [isFinishButtonDisabled1, setIsFinishButtonDisabled1] = useState(false);

  const handleDoubtResponseimp = () => {
    setShowLoadingfinish(true)
    // setShowLoadingfinish(true)
    setIsFinishButtonDisabled1(true)
    // Call studentchatui with the relevant parameters
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'imp');
    // Optionally, you can add a success message or any other logic you need here.
    // alert("You have finished the questions. Thank you for solving them!");
  };

  const [isResponseDisabled, setIsResponseDisabled] = useState(false);

  const handleDoubtResponseexample = (response) => {

    if (response === "Yes") {
      setShowLoading(true); // Show loading spinner
      setIsResponseDisabled(true); // Disable buttons after the first click

      studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = "example");
    } else {
      setDoubtResponse("To ask a question, type below or click the voice button.");
      alert("To ask a question, type below or click the voice button.");
    }
  };


  // Effect hook to monitor when loading is false (i.e., when the next message has arrived)
  useEffect(() => {
    if (!loading) {
      setShowLoading(false);  // Hide the loading spinner once the loading is complete
      setShowLoadingqns(false)
      setshowLoadingBeforeEq2(false)
      setShowLoadingfinish(false)
      setShowLoadingscore(false)
    }
  }, [loading]);

  const [isDoubtResponseDisabled, setIsDoubtResponseDisabled] = useState(false);

  const handleDoubtResponse = (response) => {
    if (response === 'Yes') {

      setDoubtResponse("To ask a question, type below or click the voice button.");
      alert("To ask a question, type below or click the voice button.");
    } else {
      setIsDoubtResponseDisabled(true); // Disable both buttons after click

      // setDoubtResponse("Ok, We can move on to Example Problem.");
      // alert("Ok, We can move on to Example Problem.");
      setShowLoading(true);
      studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'yes');

    }
  };

  //First Evaluation Starting 

  {/* Function to handle the answer submission for Yes/No responses */ }
  const [disabledQuestions, setDisabledQuestions] = useState({}); // State to track disabled buttons

  const handleDoubtResponseoneone = (response, questionId, text = 'firstone') => {
    const question = questionsone.find((q) => q.id === questionId);

    if (!question) {
      console.error("Question not found");
      return;
    }

    // Disable the buttons for the current question
    setDisabledQuestions((prev) => ({
      ...prev,
      [questionId]: true,
    }));

    // Show the loading spinner
    setShowLoadingqns(true);

    // Determine if the response matches the correct answer
    const isCorrect = response === question.correctAnswer;

    // Feedback message based on correctness
    const feedbackMessage = isCorrect ? `Correct` : `Incorrect`;

    // Send the response, question, and feedback to the backend
    studentevaluation(response, question.question, feedbackMessage, text);
  };


  {/* Function to handle the answer submission for Yes/No responses */ }
  const handleDoubtResponseonetwo = (response, questionId, text = 'firsttwo') => {
    const question = questionsone.find(q => q.id === questionId);

    if (!question) {
      console.error("Question not found");
      return;
    }
    // Disable the buttons for the current question
    setDisabledQuestions((prev) => ({
      ...prev,
      [questionId]: true,
    }));
    setShowLoadingqns(true);

    // Determine if the response matches the correct answer
    const isCorrect = response === question.correctAnswer;

    // Feedback message based on correctness
    const feedbackMessage = isCorrect
      ? `Correct`
      : `Incorrect`;

    // Send the response, question, and feedback to the backend
    studentevaluation(response, question.question, feedbackMessage, text);
  };

  const [evaluationFirstText, setEvaluationFirstText] = useState("");
  const [evaluationFirsttwoText, setEvaluationFirsttwoText] = useState("");
  const [showintro2Button, setShowintro2Button] = useState(false); // State to control button visibility
  const [showintro3Button, setShowintro3Button] = useState(false); // State to control button visibility


  useEffect(() => {
    if (message?.text?.evaluationfirstone) {
      setEvaluationFirstText(message.text.evaluationfirstone);
    }
  }, [message?.text?.evaluationfirstone]);

  useEffect(() => {
    if (message?.text?.evaluationfirsttwo) {
      setEvaluationFirsttwoText(message.text.evaluationfirsttwo);
    }
  }, [message?.text?.evaluationfirsttwo]);

  // Show button after evaluationFirstText is set
  useEffect(() => {
    if (evaluationFirstText) {
      setShowintro2Button(true);
    }
  }, [evaluationFirstText]);

  useEffect(() => {
    if (evaluationFirsttwoText) {
      setShowintro3Button(true)
    }
  }, [evaluationFirsttwoText]);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleButtonintro1Click = () => {
    setIsDisabled(true); // Disable the button after it is clicked
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'intro2');
  };

  //First Evaluation Finished 
  const [isFinishButtonDisabled5, setIsFinishButtonDisabled5] = useState(false);

  const handleFinish = () => {
    setshowLoadingBeforeEq2(true)
    setIsFinishButtonDisabled5(true);

    // Call studentchatui with the relevant parameters
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'finished');
    // Optionally, you can add a success message or any other logic you need here.
  };
  const [isFinishButtonDisabled4, setIsFinishButtonDisabled4] = useState(false);

  const handleFinishsumary = () => {
    setIsFinishButtonDisabled4(true);

    setshowLoadingBeforeEq2(true)
    // Call studentchatui with the relevant parameters
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'summary');
    // Optionally, you can add a success message or any other logic you need here.
  };
  const [isFinishButtonDisabled2, setIsFinishButtonDisabled2] = useState(false);

  const handleFinishbeforeEq = () => {
    setIsFinishButtonDisabled2(true); // Disable the button after it is clicked
    setShowLoadingfinish(true)
    // Call studentchatui with the relevant parameters
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'beforeeq');
    // Optionally, you can add a success message or any other logic you need here.
    // alert("You have finished the questions. Thank you for solving them!");
  };
  const [isFinishButtonDisabled, setIsFinishButtonDisabled] = useState(false);

  // Function to handle the finish action
  const handleFinishone = () => {
    // Trigger loading state to show the loading spinner
    setShowLoading(true);
    setIsFinishButtonDisabled(true); // Disable the button after it's clicked

    // Call the student chat UI to trigger the next message
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, text = 'definition');
  };

  // Effect hook to monitor when loading is false (i.e., when the next message has arrived)
  useEffect(() => {
    if (!loading) {
      setShowLoading(false);  // Hide the loading spinner once the loading is complete
      setShowLoadingqns(false);
      setshowLoadingBeforeEq2(false)
      setShowLoadingfinish(false)
      setShowLoadingscore(false)
    }
  }, [loading]);


  // Countdown logic for intro_question_1 and definition
  useEffect(() => {
    if (isIntroQuestionAnimationComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isIntroQuestionAnimationComplete]);

  useEffect(() => {
    if (isIntroQuestion2AnimationComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isIntroQuestion2AnimationComplete]);
  useEffect(() => {
    if (isDefinitionAnimationComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownDefinition((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isDefinitionAnimationComplete]);

  // Countdown logic for the example section
  useEffect(() => {
    if (isExampleAnimationComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownExample((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isExampleAnimationComplete]);

  // Countdown logic for the formula_combined section
  useEffect(() => {
    if (isFormulaAnimationComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownFormula((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isFormulaAnimationComplete]);
  // Countdown logic for the formula_combined section
  useEffect(() => {
    if (isQuestionForYouComplete) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isQuestionForYouComplete]);

  useEffect(() => {
    if (isImportance) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isImportance]);

  useEffect(() => {
    if (isExampleProblem) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isExampleProblem]);

  useEffect(() => {
    if (isBeforeEq) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isBeforeEq]);
  useEffect(() => {
    if (isSummary) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isSummary]);
  useEffect(() => {
    if (isCommonDifference) {
      const countdownInterval = setInterval(() => {
        setCountdownCommoDifference((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isCommonDifference]);
  useEffect(() => {
    if (isEnsureQn) {
      const countdownInterval = setInterval(() => {
        setCountdownCommoDifference((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isEnsureQn]);
  useEffect(() => {
    if (isConclusion) {
      const countdownInterval = setInterval(() => {
        setCountdownIntroQuestion((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [isConclusion]);


  const handleInputChange = (index, value) => {
    const newInputAnswers = [...inputAnswers];
    newInputAnswers[index] = value;
    setInputAnswers(newInputAnswers);
  };

  const [isScoreVisible1, setIsScoreVisible1] = useState(false);

  const handleViewScoreAndSubmit = () => {
    // Show the score
    setIsScoreVisible1(true)
    setIsScoreVisible(true);
    setShowLoadingscore(true);

    // Calculate percentage
    const percentage = ((score / questions.length) * 100).toFixed(2);
    // Call the handleSubmitthree function
    advice(percentage);
    sendPercentageToBackend(profileData, percentage);

  };

  const advice = (percentage) => {
    studentchatui(profileData, selectedSubject, selectedChapter, selectedSubtopics, percentage);
  };
  const [adviceMessage, setadviceMessage] = useState("");


  useEffect(() => {
    if (message?.text?.advice) {
      setadviceMessage(message.text.advice);
    }
  }, [message?.text?.advice]);


  const handleClick = () => {
    setShowQuestions(true);
  };
  const handleClickbeforeEq = () => {
    setshowQuestionsbeforeEq(true);
  };
  const handleClickEnsureQn = () => {
    setshowQuestionsEnsureQns(true);
  };



  return (
    <Html position={[0.3, -0.05, -4]} transform distanceFactor={0.5}>
      <div
        className="w-[1288px] h-[676px] p-8 overflow-y-auto bg-transparent opacity-80"
        ref={containerRef}
      >
        {messages.length === 0 ? (
          <div className="h-full w-full flex flex-col items-center justify-center text-center space-y-4">
            <h2 className="text-8xl font-bold text-white/90 italic">
              School of
            </h2>
            <h2 className="text-8xl font-bold font-jp text-red-600/90 italic">
              wallPiller
            </h2>
            <br />

            {/* Add the loading spinner below the text */}
            <div className="mt-6 flex justify-center items-center space-x-4">
              <span className="text-5xl font-semibold text-white">Class starting soon...</span>
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="3x" // Increase spinner size to make it more prominent
                className="text-indigo-500" // Adjust the color if needed
              />
            </div>
          </div>

        ) : (
          <div className="flex flex-col space-y-4">
            {messages.map((msg, index) => (
              <div
                key={index}
              >
                {msg.content.greeting && (
                  <h2 className="text-4xl font-bold text-green-400 mb-2">
                    {displayedText.greeting || ''}
                  </h2>
                )}
                {msg.content.intro_question_1 && (
                  <div className="mt-4 px-8 py-8 rounded-lg">
                    <h3 className="text-4xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-5xl font-bold text-white-300">
                      {(() => {
                        const lines = (displayedText.intro_question_1 || "").split("\n");

                        return lines.map((line, index) => {
                          if (line.trim() === "1, 4, 9, 16, 25, ...") {
                            return (
                              <p key={index} className="mb-2 text-blue-500 font-semibold">
                                {line}
                              </p>
                            );
                          }

                          if (line.startsWith("Question 1:")) {
                            return (
                              <p key={index} className="mb-2 text-yellow-500 font-semibold">
                                {line}
                              </p>
                            );
                          }

                          if (line.trim() === "") {
                            return <br key={index} />;
                          }

                          return (
                            <p key={index} className="mb-2 text-white">
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>
                    {isIntroQuestionAnimationComplete && (
                      <div className="mt-4 flex justify-center">
                        <button
                          onClick={() => setShowQuestionsOne1(true)}
                          className="bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Click Here
                        </button>
                      </div>
                    )}

                    {/* Only show questions if the button is clicked */}
                    {showQuestionsOne1 && (
                      <div className="mt-8 border-4 border-green-500 px-8 py-8 rounded-lg shadow-sm">
                        {questionsone
                          .filter((question) => question.id === 1)
                          .map((question) => (
                            <div key={question.id} className="mt-4">
                              {/* Add a clear label for the question */}
                              <div className="text-3xl font-semibold text-green-400 underline mb-2">
                                Question
                              </div>
                              {/* Display the question text with distinct styling */}
                              <div className="text-4xl font-bold text-gray-300">
                                {question.question}
                              </div>
                              {/* Buttons for the response */}
                              <div className="mt-4 flex justify-center space-x-4 relative">
                                {/* Yes Button */}
                                <div className="relative group">
                                  <button
                                    onClick={() =>
                                      handleDoubtResponseoneone("Yes", question.id)
                                    }
                                    className={`bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${!!disabledQuestions[question.id]
                                        ? "opacity-50 cursor-not-allowed"
                                        : "hover:bg-green-700"
                                      }`}
                                    disabled={!!disabledQuestions[question.id]} // Disable button if question is in disabled state
                                  >
                                    Yes
                                  </button>
                                  {!!disabledQuestions[question.id] && (
                                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                      Button is disabled
                                    </div>
                                  )}
                                </div>

                                {/* No Button */}
                                <div className="relative group">
                                  <button
                                    onClick={() =>
                                      handleDoubtResponseoneone("No", question.id)
                                    }
                                    className={`bg-red-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${!!disabledQuestions[question.id]
                                        ? "opacity-50 cursor-not-allowed"
                                        : "hover:bg-red-700"
                                      }`}
                                    disabled={!!disabledQuestions[question.id]} // Disable button if question is in disabled state
                                  >
                                    No
                                  </button>
                                  {!!disabledQuestions[question.id] && (
                                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                      Button is disabled
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}

                    {/* // Show loading spinner after clicking Finish */}
                    {showLoadingqns && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>

                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // You can change the color here
                          />
                        </div>
                      </div>
                    )}

                    <br />

                    {/* Add evaluationFirstText below intro_question_1 without affecting the layout */}
                    <div className="mt-4 text-4xl font-bold text-gray-200">

                      {evaluationFirstText && <h1>                    <div className="text-3xl font-semibold text-green-400 underline mb-2">
                        Answer
                      </div>{evaluationFirstText}</h1>}
                    </div>

                    {showintro2Button && (
                      <div className="mt-4 flex justify-center">
                        <div className="relative group">
                          <button
                            onClick={() => {
                              setShowLoading(true); // Show the loading spinner when the button is clicked
                              handleButtonintro1Click(); // Call the function to handle the finish action
                            }}
                            className={`bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${isDisabled ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
                              }`}
                            disabled={isDisabled} // Disable button based on the isDisabled state
                          >
                            Finish
                          </button>
                          {isDisabled && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* // Show loading spinner after clicking Finish */}
                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Next Question, please wait...</span>

                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // You can change the color here
                          />
                        </div>
                      </div>
                    )}


                  </div>
                )}

                {msg.content.intro_question_2 && (
                  <div className="mt-4 px-8 py-8 rounded-lg">
                    <h3 className="text-4xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-5xl font-bold text-white-300">
                      {/* Process and render content */}
                      {(() => {
                        // Split the content into lines
                        const lines = (displayedText.intro_question_2 || "").split("\n");

                        // Find the start of "Question 1:" and collect subsequent lines
                        let isQuestion2 = false;
                        return lines.map((line, index) => {
                          // Apply different styles based on the content
                          if (line.trim() === "Here’s a list: 2, 3, 4 ...") {
                            return (
                              <p key={index} className="mb-2 text-blue-500 font-semibold">
                                {line}
                              </p>
                            );
                          }

                          if (line.startsWith("Question 2:")) {
                            return (
                              <p key={index} className="mb-2 text-yellow-500 font-semibold">
                                {line}
                              </p>
                            );
                          }

                          if (line.trim() === "") {
                            // Render a line break for empty lines
                            return <br key={index} />;
                          }

                          // Default styling for other lines
                          return (
                            <p key={index} className="mb-2 text-white">
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>
                    {isIntroQuestion2AnimationComplete && (
                      <div className="mt-4 flex justify-center">
                        <button
                          onClick={() => setShowQuestionsOne(true)}
                          className="bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Click Here
                        </button>
                      </div>
                    )}

                    {/* Only show questions if the button is clicked */}
                    {showQuestionsOne && (
                      <div className="mt-8 border-4 border-green-500 px-8 py-8 rounded-lg shadow-sm ">
                        {questionsone
                          .filter((question) => question.id === 2)
                          .map((question) => (
                            <div key={question.id} className="mt-4">
                              <div className="text-3xl font-semibold text-green-400 underline mb-2">
                                Question
                              </div>
                              <div className="text-4xl font-bold text-white-300">
                                {question.question}
                              </div>
                              <div className="mt-4 flex justify-center space-x-4 relative">
                                {/* Yes Button */}
                                <div className="relative group">
                                  <button
                                    onClick={() => handleDoubtResponseonetwo('Yes', question.id)}
                                    className={`bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${!!disabledQuestions[question.id]
                                        ? "opacity-50 cursor-not-allowed"
                                        : "hover:bg-green-700"
                                      }`}
                                    disabled={!!disabledQuestions[question.id]} // Disable button if question is in disabled state
                                  >
                                    Yes
                                  </button>
                                  {!!disabledQuestions[question.id] && (
                                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                      Button is disabled
                                    </div>
                                  )}
                                </div>

                                {/* No Button */}
                                <div className="relative group">
                                  <button
                                    onClick={() => handleDoubtResponseonetwo('No', question.id)}
                                    className={`bg-red-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${!!disabledQuestions[question.id]
                                        ? "opacity-50 cursor-not-allowed"
                                        : "hover:bg-red-700"
                                      }`}
                                    disabled={!!disabledQuestions[question.id]} // Disable button if question is in disabled state
                                  >
                                    No
                                  </button>
                                  {!!disabledQuestions[question.id] && (
                                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                      Button is disabled
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}

                    {showLoadingqns && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>

                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // You can change the color here
                          />
                        </div>
                      </div>
                    )}
                    {/* Add evaluationFirsttwoText below intro_question_1 without affecting the layout */}
                    <div className="mt-4 text-4xl font-bold text-gray-200">
                      {evaluationFirsttwoText && <p>                                        <div className="text-3xl font-semibold text-green-400 underline mb-2">
                        Answer
                      </div>{evaluationFirsttwoText}</p>}
                    </div>
                    {/* Finish Button */}
                    {showintro3Button && (
                      <div className="mt-4 flex justify-center">
                        <div className="relative group">
                          <button
                            onClick={handleFinishone}
                            className={`bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${isFinishButtonDisabled ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
                              }`}
                            disabled={isFinishButtonDisabled} // Disable button based on the isFinishButtonDisabled state
                          >
                            Finish
                          </button>
                          {isFinishButtonDisabled && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>
                      </div>
                    )}



                    {/* Next Definition and Loading Icon */}
                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Next Definition Text */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>

                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // You can vary the color here
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {msg.content.definition && (
                  <div className="mt-4 border-4 border-yellow-500 bg-gray-00 px-8 py-8 rounded-lg">
                    <h3 className="text-4xl font-bold text-gray-300 underline mb-2">Note</h3>
                    <div className="text-4xl font-bold">
                      {(() => {
                        // Safely access and split 'definition' into lines from the animated text
                        const lines = (displayedText.definition || "").split("\n");

                        return lines.map((line, index) => {
                          // Define default style for the entire line
                          let lineStyle = "text-white";
                          if (
                            line.trim() ===
                            "- Here in second Sequance have a common difference."
                          ) {
                            lineStyle = "text-yellow-400";
                          }

                          return (
                            <React.Fragment key={index}>
                              {/* Add a line break before specific lines */}
                              {line.trim() ===
                                "- Here in second Sequance have a common difference." && (
                                  <br />
                                )}
                              <p className={`mb-2 ${lineStyle}`}>
                                {line.split(/(1, 4, 9, 16, 25, \.\.\.|2,3,4\.\.)/).map((part, partIndex) => {
                                  // Check for specific sequences and apply distinct color
                                  if (part === "1, 4, 9, 16, 25, ..." || part === "2,3,4..") {
                                    return (
                                      <span key={partIndex} className="text-yellow-400">
                                        {part}
                                      </span>
                                    );
                                  }
                                  // Return the rest of the line in default style
                                  return <span key={partIndex}>{part}</span>;
                                })}
                              </p>
                            </React.Fragment>
                          );
                        });
                      })()}
                    </div>

                    {/* Countdown for definition */}
                    {isDefinitionAnimationComplete && countdownDefinition > 0 && (
                      <div className="bg-gray-800 justify-center text-white p-4 rounded-lg shadow-md max-w-sm mx-auto flex items-center space-x-4">
                        <div className="text-center">
                          <h2 className="text-3xl font-semibold">
                            <i className="fas fa-hourglass-half text-yellow-500 animate-spin"></i> Next, What is common difference...
                          </h2>
                          <p className="mt-2">
                            <span className="inline-block text-2xl bg-gray-900 text-yellow-400 font-bold rounded-full px-3 py-1 shadow">
                              {countdownDefinition}
                              <span className="ml-1 text-2xl text-gray-300">seconds left</span>
                            </span>
                          </p>
                        </div>
                        <div>
                          <i className="fas fa-question-circle text-4xl text-yellow-500"></i>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {msg.content.commondifference && (
                  <div className="mt-4 bg-gray-00 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-4xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-4xl font-bold">
                      {(() => {
                        // Safely access and split 'commondifference' into lines from the animated text
                        const lines = (displayedText.commondifference || "").split("\n");

                        return lines.map((line, index) => {
                          // Define default style for the entire line
                          let lineStyle = "text-white";

                          // Highlight specific lines
                          if (line.trim() === "- So, sequences that have a common difference are called arithmetic sequences.") {
                            lineStyle = "text-yellow-400";
                          }

                          return (
                            <React.Fragment key={index}>
                              {/* Add a line break and specific color for the first and second sequence lines */}
                              {line.trim() === "Take The First sequance 1, 4, 9, 16, 25..." && (
                                <p className="mb-2 text-yellow-400">
                                  {line}
                                </p>
                              )}

                              {/* Add a line break before "Second sequence 2,3,4..." */}
                              {line.trim() === "Second sequance 2,3,4..." && (
                                <>
                                  <br /> {/* Add a line break here */}
                                  <p className="mb-2 text-yellow-400">
                                    {line}
                                  </p>
                                </>
                              )}

                              {/* Line handling for "So The difference of neighbouring terms are not constant." */}
                              {line.trim() === "So The difference of neighbouring terms are not constant." && (
                                <p className={`mb-2 ${lineStyle}`}>{line}</p>
                              )}

                              {/* Line break after the specified line */}
                              {line.trim() === "So The difference of neighbouring terms are constant. which is known as common difference." && (
                                <>
                                  <p className={`mb-2 ${lineStyle}`}>{line}</p>
                                  <br />
                                </>
                              )}

                              {/* Default handling for other lines */}
                              {line.trim() !== "Take The First sequance 1, 4, 9, 16, 25..." &&
                                line.trim() !== "Second sequance 2,3,4..." &&
                                line.trim() !== "So The difference of neighbouring terms are not constant." &&
                                line.trim() !== "So The difference of neighbouring terms are constant. which is known as common difference." && (
                                  <p className={`mb-2 ${lineStyle}`}>
                                    {line.split(/(1, 4, 9, 16, 25, \.\.\.|2,3,4\.\.)/).map((part, partIndex) => {
                                      // Check for specific sequences and apply distinct color
                                      if (part === "1, 4, 9, 16, 25, ..." || part === "2,3,4..") {
                                        return (
                                          <span key={partIndex} className="text-yellow-400">
                                            {part}
                                          </span>
                                        );
                                      }
                                      // Return the rest of the line in default style
                                      return <span key={partIndex}>{part}</span>;
                                    })}
                                  </p>
                                )}
                            </React.Fragment>
                          );
                        });
                      })()}
                    </div>


                    {/* Countdown for definition */}
                    {isCommonDifference && (
                      <div className="mt-6 p-6 px-3 py-2 rounded-lg">
                        <h3 className="text-2xl text-gray-300 font-semibold text-center">
                          Do you get me?
                        </h3>
                        <div className="mt-4 flex justify-center space-x-4">
                          <div className="relative group">
                            <button
                              onClick={() => handleDoubtResponseexample("Yes")}
                              className={`bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${isResponseDisabled ? "opacity-50 cursor-not-allowed" : "hover:bg-green-700"
                                }`}
                              disabled={isResponseDisabled}
                            >
                              Yes
                            </button>
                            {isResponseDisabled && (
                              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Button is disabled
                              </div>
                            )}
                          </div>
                          <div className="relative group">
                            <button
                              onClick={() => handleDoubtResponseexample("No")}
                              className={`bg-red-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold `}
                            >
                              No
                            </button>

                          </div>
                        </div>
                      </div>
                    )}


                    {/* // Show loading spinner after clicking Finish */}
                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Next, lets see some Examples...</span>

                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // You can change the color here
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {msg.content.example && (
                  <div className="mt-4 px-8 py-8 rounded-lg">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-4xl font-bold text-white-300">
                      {(() => {
                        const lines = (displayedText.example || "").split("\n");
                        return lines.map((line, index) => {
                          let lineClass = "text-white:"; // Default class
                          if (line.startsWith("For Example:")) {
                            lineClass = "text-yellow-300"; // Style for Number example
                          } else if (line.startsWith("Real life example:")) {
                            lineClass = "text-green-500"; // Style for Real life example
                          }
                          return (
                            <p key={index} className={`mb-2 ${lineClass}`}>
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>
                    {/* Countdown for definition
                    {isExampleAnimationComplete && countdownExample > 0 && (
                      <div className="bg-gray-800 justify-center text-white p-4 rounded-lg shadow-md max-w-sm mx-auto flex items-center space-x-4">
                        <div className="text-center">
                          <h2 className="text-3xl font-semibold">
                            <i className="fas fa-hourglass-half text-yellow-500 animate-spin"></i> Next do some question...
                          </h2>
                          <p className="mt-2">
                            <span className="inline-block bg-indigo-600 text-2xl text-yellow-400 font-bold rounded-full px-3 py-1 shadow">
                              {countdownExample}
                              <span className="ml-1 text-2xl text-gray-300">seconds left</span>
                            </span>
                          </p>
                        </div>
                        <div>
                          <i className="fas fa-question-circle text-4xl text-yellow-500"></i>
                        </div>
                      </div>
                    )} */}
                    {isExampleAnimationComplete && (
                      <div className="mt-4 flex justify-center">
                        <div className="relative group">
                          <button
                            onClick={handleDoubtResponseimp}
                            className={`bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${isFinishButtonDisabled1 ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
                              }`}
                            disabled={isFinishButtonDisabled1} // Disable button based on the isFinishButtonDisabled state
                          >
                            Finish
                          </button>
                          {isFinishButtonDisabled1 && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>
                      </div>


                    )}

                    {showLoadingfinish && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">How we can find next terms in the sequence?..</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}

                  </div>
                )}

                {msg.content.ensureqns && (
                  <div className="mt-4  px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-4xl font-bold text-white-200">
                      {(() => {
                        // Safely access and split 'conclusion' into lines from the animated text
                        const lines = (displayedText.ensureqns || "").split("\n");

                        // Loop through each line and render it with styles
                        return lines.map((line, index) => (
                          <p key={index} className="mb-2">
                            {line}
                          </p>
                        ));
                      })()}
                    </div>
                    {isEnsureQn && (
                      <button
                        onClick={handleClickEnsureQn}
                        className="bg-blue-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                      >
                        Click here to solve
                      </button>
                    )}
                    {showQuestionsEnsureQns && (
                      <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                        {/* Display question and input field for the first question */}
                        <div className="mb-4">
                          <p className="text-4xl font-semibold text-white mb-4">{questionsEnsure[0].question}</p>
                          <input
                            type="text"
                            className="w-full text-xl bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500  font-bold"
                            placeholder="Your answer"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                        </div>

                        {/* Submit Button */}
                        <div className="relative group">
                          <button
                            onClick={handleSubmitEnsureeq1}
                            className={`w-full bg-blue-500 text-2xl text-white py-3 rounded-lg shadow-md transition-all duration-200 transform font-bold ${isDisabledEnsureEq1 ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700 hover:scale-105"
                              }`}
                            disabled={isDisabledEnsureEq1}
                          >
                            Submit Answer
                          </button>
                          {isDisabledEnsureEq1 && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>

                        {/* Show loading spinner */}
                        {showLoadingqns && (
                          <div className="mt-6 flex justify-center items-center">
                            <div className="flex items-center space-x-4">
                              {/* Text to indicate loading */}
                              <span className="text-3xl font-semibold text-white">How we can find next terms in the sequence?..</span>
                              {/* FontAwesome spinning icon */}
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="text-indigo-500"  // Customize the color here
                              />
                            </div>
                          </div>
                        )}
                        {/* Evaluation Message Section */}
                        <div className="text-center mt-6">
                          {ensureEq1 && (
                            <p className="text-4xl font-semibold text-gray-300">{ensureEq1}</p>
                          )}
                        </div>
                      </div>
                    )}
                    {ensureEq1 && (
                      <div className="mt-6"> {/* Adding margin-top for spacing */}
                        <button
                          onClick={nextEvaluationEnsuretwo}
                          className="bg-green-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Next
                        </button>
                      </div>
                    )}

                    {showNextevaluationEnsuretwo && (
                      <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                        {/* Display question and input field for the first question */}
                        <div className="mb-4">
                          <p className="text-4xl font-semibold text-white mb-4">{questionsEnsure[1].question}</p>
                          <input
                            type="text" // Change the input type to text
                            className="w-full text-xl bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 font-bold"
                            placeholder="Enter your sequence (e.g., 1, 2, 3)"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />

                        </div>

                        {/* Submit Button */}
                        <div className="relative group">
                          <button
                            onClick={handleSubmitEnsureeq2}
                            className={`w-full bg-blue-500 text-2xl text-white py-3 rounded-lg shadow-md transition-all duration-200 transform font-bold ${isDisabledEnsureEq2 ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700 hover:scale-105"
                              }`}
                            disabled={isDisabledEnsureEq2}
                          >
                            Submit Answer
                          </button>
                          {isDisabledEnsureEq2 && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>

                        {/* Show loading spinner */}
                        {showLoadingBeforeEq2 && (
                          <div className="mt-6 flex justify-center items-center">
                            <div className="flex items-center space-x-4">
                              {/* Text to indicate loading */}
                              <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                              {/* FontAwesome spinning icon */}
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="text-indigo-500"  // Customize the color here
                              />
                            </div>
                          </div>
                        )}
                        {/* Evaluation Message Section */}
                        <div className="mt-6">
                          {ensureEq2 && (
                            <div className="text-4xl font-semibold text-gray-300">
                              {ensureEq2.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                              ))}
                            </div>
                          )}
                        </div>

                      </div>
                    )}
                    {ensureEq2 && (
                      <div className="mt-4 flex justify-center">
                        <div className="relative group">
                          <button
                            onClick={handleDoubtResponseimp}
                            className={`bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${isFinishButtonDisabled1 ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
                              }`}
                            disabled={isFinishButtonDisabled1} // Disable button based on the isFinishButtonDisabled state
                          >
                            Finish
                          </button>
                          {isFinishButtonDisabled1 && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>
                      </div>


                    )}

                    {showLoadingfinish && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}

                  </div>
                )}
                {msg.content.importance && (
                  <div className="mt-4 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-4xl font-bold text-gray-300 underline mb-2">How we can find next terms in the sequence?..</h3>
                    <div className="text-4xl font-bold text-white-300">
                      {(() => {
                        const lines = (displayedText.importance || "").split("\n");

                        return lines.map((line, index) => {
                          let colorClass = ""; // Class for specific sections of the text
                          let additionalStyle = {}; // Additional styles like line breaks

                          // Apply styles based on specific keywords or sections
                          if (line.startsWith("  - Take the sequence")) {
                            colorClass = "text-white-500";
                          } else if (line.startsWith("Common Difference:")) {
                            colorClass = "text-yellow-400 font-bold";
                          } else if (line.startsWith("  - ")) {
                            colorClass = "text-yellow-400";
                          } else if (line.startsWith("Question:")) {
                            colorClass = "text-blue-500 font-bold";
                          } else if (line.startsWith("Question for you :")) {
                            colorClass = "text-blue-500 font-bold";
                          } else if (line.startsWith("Answer:")) {
                            colorClass = "text-yellow-400 font-bold";
                            additionalStyle = { marginBottom: "1rem" };
                          } else if (line.trim() === "") {
                            // Render a line break for empty lines
                            return <br key={index} />;
                          }

                          // Render each line with appropriate styles
                          return (
                            <p
                              key={index}
                              className={`mb-2 ${colorClass ? colorClass : "text-white"}`}
                              style={additionalStyle}
                            >
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>
                    {isImportance && (
                      <button
                        onClick={handleClickbeforeEq}
                        className="bg-blue-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                      >
                        Click here to solve
                      </button>
                    )}
                    {showQuestionsbeforeEq && (
                      <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                        {/* Display question and input field for the first question */}
                        <div className="mb-4">
                          <p className="text-4xl font-semibold text-white mb-4">{questionsbeforeeq[0].question}</p>
                          <input
                            type="number"
                            className="w-full text-xl bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500  font-bold"
                            placeholder="Your answer"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                        </div>

                        {/* Submit Button */}
                        <div className="relative group">
                          <button
                            onClick={handleSubmitbeforeq1}
                            className={`w-full bg-blue-500 text-2xl text-white py-3 rounded-lg shadow-md transition-all duration-200 transform font-bold ${isDisabledEnsureEq3 ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700 hover:scale-105"
                              }`}
                            disabled={isDisabledEnsureEq3}
                          >
                            Submit Answer
                          </button>
                          {isDisabledEnsureEq3 && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>

                        {/* Show loading spinner */}
                        {showLoadingqns && (
                          <div className="mt-6 flex justify-center items-center">
                            <div className="flex items-center space-x-4">
                              {/* Text to indicate loading */}
                              <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                              {/* FontAwesome spinning icon */}
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="text-indigo-500"  // Customize the color here
                              />
                            </div>
                          </div>
                        )}
                        {/* Evaluation Message Section */}
                        <div className="text-center mt-6">
                          {beforeEq1 && (
                            <p className="text-4xl font-semibold text-gray-300">{beforeEq1}</p>
                          )}
                        </div>
                      </div>
                    )}
                    {beforeEq1 && (
                      <div className="mt-2 max-w-4xl mx-auto p-8 rounded-lg shadow-xl">
                        {/* Display question and input field for the first question */}
                        <div className="mb-4">
                          <p className="text-4xl font-semibold text-white mb-4">{questionsbeforeeq[1].question}</p>
                          <input
                            type="number"
                            className="w-full text-xl bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500  font-bold"
                            placeholder="Your answer"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                        </div>

                        {/* Submit Button */}
                        <div className="relative group">
                          <button
                            onClick={handleSubmitbeforeq2}
                            className={`w-full bg-blue-500 text-2xl text-white py-3 rounded-lg shadow-md transition-all duration-200 transform font-bold ${isDisabledEnsureEq4 ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700 hover:scale-105"
                              }`}
                            disabled={isDisabledEnsureEq4}
                          >
                            Submit Answer
                          </button>
                          {isDisabledEnsureEq4 && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>

                        {/* Show loading spinner */}
                        {showLoadingBeforeEq2 && (
                          <div className="mt-6 flex justify-center items-center">
                            <div className="flex items-center space-x-4">
                              {/* Text to indicate loading */}
                              <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                              {/* FontAwesome spinning icon */}
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                size="2x"
                                className="text-indigo-500"  // Customize the color here
                              />
                            </div>
                          </div>
                        )}
                        {/* Evaluation Message Section */}
                        <div className="mt-6">
                          {beforeEq2 && (
                            <div className="text-4xl font-semibold text-gray-300">
                              {beforeEq2.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                              ))}
                            </div>
                          )}
                        </div>

                      </div>
                    )}
                    {beforeEq2 && (
                      <div className="mt-4 flex justify-center">
                        <div className="relative group">
                          <button
                            onClick={handleFinishbeforeEq}
                            className={`bg-blue-500 text-3xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${isFinishButtonDisabled2 ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
                              }`}
                            disabled={isFinishButtonDisabled2} // Disable button based on the isFinishButtonDisabled state
                          >
                            Finish
                          </button>
                          {isFinishButtonDisabled2 && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>
                      </div>

                    )}

                    {showLoadingfinish && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}

                  </div>
                )}
                {msg.content.beforeeq && (
                  <div className="mt-4  px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-4xl font-bold text-white-200">
                      {(() => {
                        // Safely access and split 'beforeeq' into lines from the backend text
                        const lines = (displayedText.beforeeq || "").split("\n");

                        return lines.flatMap((line, index) => {
                          // Array to hold current line and optional line break
                          const elements = [];

                          // Add the current line
                          if (line.includes("We have a solution for this problem")) {
                            elements.push(
                              <p key={index} className="mb-2 text-yellow-500">
                                {line}
                              </p>
                            );
                          } else {
                            elements.push(
                              <p key={index} className="mb-2">
                                {line}
                              </p>
                            );
                          }

                          // Add a line break after a specific line
                          if (
                            line.includes("It seems difficult right?") // Match the line
                          ) {
                            elements.push(<br key={`${index}-break`} />);
                          }

                          return elements;
                        });
                      })()}
                    </div>

                    {isBeforeEq && (
                      <div className="mt-6 p-6 px-3 py-2 rounded-lg">
                        <h3 className="text-3xl text-gray-300 font-semibold text-center">Do you get me?</h3>
                        <div className="mt-4 flex justify-center space-x-4">

                          {/* "Yes" Button */}
                          <div className="relative group">
                            <button
                              onClick={() => handleDoubtResponseformula("Yes")}
                              className={`bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform duration-200 font-bold ${isButtonDisabled ? "opacity-50 cursor-not-allowed" : "hover:bg-green-700 hover:scale-105"
                                }`}
                              disabled={isButtonDisabled}
                            >
                              Yes
                            </button>
                            {isButtonDisabled && (
                              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Button is disabled
                              </div>
                            )}
                          </div>

                          {/* "No" Button */}
                          <div className="relative group">
                            <button
                              onClick={() => handleDoubtResponseformula("No")}
                              className={`bg-red-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform duration-200 font-bold `}
                            >
                              No
                            </button>

                          </div>

                        </div>
                      </div>
                    )}

                    {/* Display loading icon if showLoading is true and 'Yes' was clicked */}
                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          <span className="text-3xl font-semibold text-white">Next Equation, please wait...</span>
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"
                          />
                        </div>
                      </div>
                    )}
                    {/* {doubtResponse && (
                        <div className="mt-3 text-center text-lg font-bold text-green-400 bg-gray-800 p-3 rounded-md">
                          {doubtResponse}
                        </div>
                      )} */}
                  </div>
                )}
                {msg.content.formula_combined && (
                  <div className="mt-4 border-4 border-yellow-500 px-8 py-8 rounded-lg">
                    <h3 className="text-3xl font-bold text-gray-300 underline mb-2"></h3>
                    <div className="text-4xl font-bold text-white-300">
                      {(() => {
                        // Safely split and render content with optional section titles
                        const lines = (displayedText.formula_combined || "").split("\n");

                        return lines.map((line, index) => {
                          let lineStyle = "text-white"; // Default color
                          if (
                            line.trim() ===
                            "Xn= X1 + ((n -1) ×d)"
                          ) {
                            lineStyle = "text-yellow-400"; // Yellow color
                          } else if (
                            line.startsWith() ===
                            "If we have an arithmetic sequence X1, X2, X3, ... Xn:"
                          ) {
                            lineStyle = "text-yellow-400"; // Red color
                          }

                          return (
                            <p key={index} className={`mb-2 ${lineStyle}`}>
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>
                    {/* Countdown for formula_combined */}
                    {isFormulaAnimationComplete && (
                      <div className="justify-center text-white p-4 rounded-lg shadow-md max-w-sm mx-auto flex items-center space-x-4">
                        <div className="text-center relative group">
                          <button
                            onClick={nextViewEquation}
                            className={`bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform duration-200 font-bold ${isViewEquationDisabled
                                ? "opacity-50 cursor-not-allowed"
                                : "hover:bg-green-700 hover:scale-105"
                              }`}
                            disabled={isViewEquationDisabled}
                          >
                            View Equation
                          </button>
                          {isViewEquationDisabled && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {showLoadingqns && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}
                    {shownviewEquation && (
                      <div className="mt-6 p-6 px-3 py-2 rounded-lg shadow-lg">
                        {/* Dummy Message */}
                        <div className="text-500 text-5xl font-bold  text-white py-4 rounded-md">
                          <p>Xn = X1 + ((n - 1) × d)</p>
                          <p> - The term we need to find is n</p>
                          <p> - First term X1</p>
                          <p> - Common Difference d</p>

                        </div>
                      </div>
                    )}

                    {shownviewEquation && (
                      <div className="justify-center text-white p-4 rounded-lg shadow-md max-w-sm mx-auto flex items-center space-x-4">
                        <div className="text-center relative group">
                          <button
                            onClick={handleexampleProblem}
                            className={`bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform duration-200 font-bold ${isFinishButtonDisabled3
                                ? "opacity-50 cursor-not-allowed"
                                : "hover:bg-green-700 hover:scale-105"
                              }`}
                            disabled={isFinishButtonDisabled3}
                          >
                            Do a Problem
                          </button>
                          {isFinishButtonDisabled3 && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          <span className="text-3xl font-semibold text-white">We can do a problem, please wait...</span>
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {msg.content.example_problem && (
                  <div className="mt-4  px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-3xl font-bold text-gray-300 underline mb-2"> </h3>
                    <br />
                    <div className="text-4xl font-bold text-white-300">
                      {(() => {
                        const lines = (displayedText.example_problem || "").split("\n"); // Split by single newline
                        let processedLines = [];

                        for (let i = 0; i < lines.length; i++) {
                          const line = lines[i];
                          const nextLine = lines[i + 1];

                          // Check for \n\n: Insert a line break for double newlines
                          if (line === "" && nextLine === "") {
                            processedLines.push(<br key={`gap-${i}`} />);
                            i++; // Skip the next line as part of \n\n
                            continue;
                          }

                          // Check if the current line needs to be followed by an extra line break
                          const needsExtraBreak =
                            line.includes("Can you find the 8th term of the sequence.") ||
                            line.includes("Equation is Xn= X1 +((n -1) ×d)") ||
                            line.includes("First value, X1 =1");

                          // Determine the color class for steps
                          const isStep1 = line.includes("Step 1:");
                          const isStep2 = line.includes("Step 2:");
                          const stepColorClass = isStep1
                            ? "text-blue-500 font-bold"
                            : isStep2
                              ? "text-green-500 font-bold"
                              : "text-white-300";

                          // Push the current line with styling
                          if (line.trim() !== "") {
                            processedLines.push(
                              <p key={i} className={`mb-2 ${stepColorClass}`}>
                                {line}
                              </p>
                            );
                          }

                          // Add an extra line break if the line requires it
                          if (needsExtraBreak) {
                            processedLines.push(<br key={`extra-break-${i}`} />);
                          }
                        }

                        return processedLines;
                      })()}
                    </div>


                    {isExampleProblem && (
                      <div className="mt-6"> {/* Adding margin-top for spacing */}
                        <div className="relative group">
                          <button
                            onClick={nextExampleProblem}
                            className={`bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform duration-200 font-bold ${isAnswerButtonDisabled
                                ? "opacity-50 cursor-not-allowed"
                                : "hover:bg-green-700 hover:scale-105"
                              }`}
                            disabled={isAnswerButtonDisabled}
                          >
                            Answer
                          </button>
                          {isAnswerButtonDisabled && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {showLoadingqns && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}
                    {shownextExampleProblem && (
                      <div className="mt-6  p-6 px-3 py-2 rounded-lg shadow-lg">
                        {/* Display the static text */}
                        <div className="text-5xl text-yellow-500 font-bold text-white">
                          <h3>Sequance = 1, 4, 7, 10 ...</h3>
                          <br /><h3>Equation, Xn = X1 + ((n -1) × d).</h3>
                          <h3>Step 1: Find Common Difference(d)</h3>
                          <br />
                        </div>

                        {/* Input fields for d and X1 */}
                        <div className="mt-4">
                          {/* Input for "d" */}
                          <div className="flex items-center mb-4">

                            <h3 className="text-white text-4xl font-bold mr-2">Common difference, d = </h3>
                            <input
                              type="text"
                              className="p-1 w-24 text-3xl font-bold rounded-md border border-gray-300 text-black bg-white"
                              value={d}
                              onChange={(e) => setD(e.target.value)}
                            />
                            <div className="relative group">
                              <button
                                onClick={verifyD}
                                className={`text-2xl font-bold ml-4 p-2 rounded-md transition-transform duration-200 ${isVerifyDisabled
                                    ? "bg-gray-500 text-gray-300 cursor-not-allowed"
                                    : "bg-blue-500 text-white hover:bg-blue-700 hover:scale-105"
                                  }`}
                                disabled={isVerifyDisabled}
                              >
                                Verify
                              </button>
                              {isVerifyDisabled && (
                                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                  Button is disabled
                                </div>
                              )}
                            </div>

                            {feedback1 && <p
                            className={`mt-2 ml-4 ${feedback1 === "Correct!" ? "text-green-500" : "text-red-500"
                              } text-3xl font-bold`}
                          >
                            {feedback1}
                          </p>}
                          </div>

                          {/* Input for "X1" */}
                          <div className="flex items-center">
                            <h3 className="text-4xl font-bold text-white mr-2">First term, X1 = </h3>
                            <input
                              type="text"
                              className="p-1 w-24 text-3xl font-bold rounded-md border border-gray-300 text-black bg-white"
                              value={X1}
                              onChange={(e) => setX1(e.target.value)}
                            />
                            <div className="relative group">
                              <button
                                onClick={verifyX1}
                                className={`ml-4 p-2 text-2xl font-bold rounded-md transition-transform duration-200 ${isVerifyX1Disabled
                                    ? "bg-gray-500 text-gray-300 cursor-not-allowed"
                                    : "bg-blue-500 text-white hover:bg-blue-700 hover:scale-105"
                                  }`}
                                disabled={isVerifyX1Disabled}
                              >
                                Verify
                              </button>
                              {isVerifyX1Disabled && (
                                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                  Button is disabled
                                </div>
                              )}
                            </div>
                            {feedback2 &&                           <p
                            className={`mt-2 ml-4 ${feedback2 === "Correct!" ? "text-green-500" : "text-red-500"
                              } text-3xl font-bold`}
                          >
                            {feedback2}
                          </p>}
                          </div>
                        </div>
                      </div>
                    )}


                    {feedback2 && (
                      <div className="mt-6"> {/* Adding margin-top for spacing */}
                        <div className="relative group">
                          <button
                            onClick={nextSteptwo}
                            className={`bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform duration-200 font-bold ${isStepTwoDisabled
                                ? "bg-gray-500 text-gray-300 cursor-not-allowed"
                                : "hover:bg-green-700 hover:scale-105"
                              }`}
                            disabled={isStepTwoDisabled}
                          >
                            Step 2
                          </button>
                          {isStepTwoDisabled && (
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              Button is disabled
                            </div>
                          )}
                        </div>

                      </div>
                    )}
                    {showLoadingBeforeEq2 && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}
                    {shownextnextSteptwo && (
                      <div className="mt-6  p-6 px-3 py-2 rounded-lg ">
                        {/* Step 2 Static Content */}
                        <div className="text-5xl font-bold text-white mb-4">
                          <h3>Step 2: to find nth term</h3>
                          <h3>Xn = X1 + ((n - 1) × d)</h3>
                          <h3>X8 = X1 + ((n - 1) × d).</h3>
                          <h3>X8 = 1 + ((8 - 1) × 3)</h3><br />
                        </div>

                        {/* Input Field and Verify Button in One Line */}
                        <div className="flex items-center text-5xl font-bold text-white">
                          <br /><h3 className="mr-2">8th term is =</h3>
                          <input
                            type="text"
                            className="p-1 w-24 text-5xl font-bold rounded-md border border-gray-300 text-black bg-white"
                            value={answer1}
                            onChange={(e) => setAnswer1(e.target.value)}
                          />
                          <div className="relative group">
                            <button
                              onClick={verifyAnswer}
                              className={`ml-4 p-2 rounded-md text-white text-3xl font-bold transition-transform transform duration-200 ${isVerifyButtonDisabled
                                  ? "bg-gray-500 text-gray-300 cursor-not-allowed"
                                  : "bg-blue-500 hover:bg-blue-700 hover:scale-105"
                                }`}
                              disabled={isVerifyButtonDisabled}
                            >
                              Verify
                            </button>
                            {isVerifyButtonDisabled && (
                              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Button is disabled
                              </div>
                            )}
                          </div>

                        </div>

                        {/* Feedback */}
                        {feedback3 && (
                          <p
                            className={`mt-2 ${feedback3 === "Correct!" ? "text-green-500" : "text-red-500"
                              } text-4xl font-bold`}
                          >
                            {feedback3}
                          </p>
                        )}
                      </div>
                    )}


                    {feedback3 && (
                      <div className="mt-6 p-6 px-3 py-2 rounded-lg">
                        {/* Doubt Question Section */}
                        <div>
                          <h3 className="text-3xl text-gray-300 font-semibold text-center">
                            Do you have any doubt?
                          </h3>

                          <div className="mt-4 flex justify-center space-x-4">
                            {/* Yes Button */}
                            <div className="relative group">
                              <button
                                onClick={() => handleDoubtResponse("Yes")}
                                className={`text-2xl bg-green-500 text-white px-6 py-3 rounded-lg shadow-md transition-transform transform duration-200 font-bold `}
                              >
                                Yes
                              </button>

                            </div>

                            {/* No Button */}
                            <div className="relative group">
                              <button
                                onClick={() => handleDoubtResponse("No")}
                                className={`text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform duration-200 font-bold ${isDoubtResponseDisabled
                                    ? "bg-gray-500 cursor-not-allowed text-gray-300"
                                    : "bg-red-500 hover:bg-red-700 hover:scale-105"
                                  }`}
                                disabled={isDoubtResponseDisabled}
                              >
                                No
                              </button>
                              {isDoubtResponseDisabled && (
                                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                  Button is disabled
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Display loading icon if showLoading is true and 'Yes' was clicked */}
                    {showLoading && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          <span className="text-3xl font-semibold text-white">Next Questions for you</span>
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"
                          />
                        </div>
                      </div>
                    )}

                  </div>
                )}
                {msg.content.questions_for_you_to_solve && (
                  <div className="mt-4">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2">
                    </h3>
                    <div className="text-4xl font-bold text-white-300">
                      {(() => {
                        // Render animated text with line breaks
                        const lines = (displayedText.questions_for_you_to_solve || "").split("\n");
                        return lines.map((line, index) => (
                          <p key={index} className="mb-2">
                            {line}
                          </p>
                        ));
                      })()}
                    </div>
                    <div className="mt-4">
                      {isQuestionForYouComplete && (
                        <button
                          onClick={handleClick}
                          className="bg-blue-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                        >
                          Click here to solve
                        </button>
                      )}
                      {showQuestions && (
                        <div className="mt-2 max-w-5xl mx-auto p-8 rounded-lg shadow-xl">
                          {/* Display question and input field for the first question */}
                          <div className="mb-4">
                            <p className="text-5xl font-semibold text-white mb-4">{questions[0].question}</p>
                            <input
                              type="number"
                              className="w-full text-4xl bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500  font-bold"
                              placeholder="Your answer"
                              value={answer}
                              onChange={(e) => setAnswer(e.target.value)}
                            />
                          </div>

                          {/* Submit Button */}
                          <div className="flex justify-center mb-4 relative group">
                            <button
                              onClick={handleSubmitone}
                              className={`w-full py-3 rounded-lg shadow-md text-2xl font-bold transition-all duration-200 transform ${isSubmitDisabled
                                  ? "bg-gray-500 text-gray-300 cursor-not-allowed"
                                  : "bg-blue-500 text-white hover:bg-blue-700 hover:scale-105"
                                }`}
                              disabled={isSubmitDisabled}
                            >
                              Submit Answer
                            </button>
                            {isSubmitDisabled && (
                              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Answer already submitted
                              </div>
                            )}
                          </div>


                          {/* Show loading spinner */}
                          {showLoadingscore && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                          {/* Evaluation Message Section */}
                          <div className="mt-6">
                            {evaluationSecondone && (
                              <div className="text-3xl font-semibold text-gray-300">
                                {evaluationSecondone.split('\n').map((line, index) => (
                                  <p
                                    key={index}
                                    className={line.startsWith(' - ') ? 'text-yellow-500' : ''}
                                  >
                                    {line}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>

                          {evaluationSecondone && (
                            <div className="mt-6"> {/* Adding margin-top for spacing */}
                              <button
                                onClick={nextEvaluationSecondone}
                                className="bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                              >
                                Next
                              </button>
                            </div>
                          )}


                        </div>
                      )}

                      {/* Display second question after submitting the first */}
                      {showNextevaluationsecondOne && (
                        <div className="mt-2 max-w-5xl mx-auto p-8 rounded-lg shadow-xl">
                          <div className="mb-4">
                            <p className="text-5xl font-semibold text-white mb-4">{questions[1].question}</p>
                            <input
                              type="number"
                              className="w-full bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-4xl font-bold"
                              placeholder="Your answer"
                              value={answer}
                              onChange={(e) => setAnswer(e.target.value)}
                            />
                          </div>
                          {/* Submit Button */}
                          <div className="flex justify-center mb-4 relative group">
                            <button
                              onClick={handleSubmittwo}
                              className={`w-full py-3 rounded-lg shadow-md text-2xl font-bold transition-all duration-200 transform ${isSubmitDisabled2
                                  ? "bg-gray-500 text-gray-300 cursor-not-allowed"
                                  : "bg-blue-500 text-white hover:bg-blue-700 hover:scale-105"
                                }`}
                              disabled={isSubmitDisabled2}
                            >
                              Submit Answer
                            </button>
                            {isSubmitDisabled2 && (
                              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Answer already submitted
                              </div>
                            )}
                          </div>


                          {/* Show loading spinner */}
                          {showLoadingqns && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                          <div className="mt-6">
                            {evaluationSecondtwo && (
                              <div className="text-3xl font-semibold text-gray-300">
                                {evaluationSecondtwo.split('\n').map((line, index) => (
                                  <p
                                    key={index}
                                    className={line.startsWith(' - ') ? 'text-yellow-500' : ''}
                                  >
                                    {line}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                          {evaluationSecondtwo && (
                            <div className="mt-6"> {/* Adding margin-top for spacing */}
                              <button
                                onClick={nextEvaluationSecondtwo}
                                className="bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                              >
                                Next
                              </button>
                            </div>
                          )}
                        </div>
                      )}


                      {/* Display third question after submitting the second */}
                      {showNextevaluationsecondTwo && (
                        <div className="mt-2 max-w-5xl mx-auto p-8 rounded-lg shadow-xl">
                          <div className="mb-4">
                            <p className="text-5xl font-semibold text-white mb-4">{questions[2].question}</p>
                            <input
                              type="number"
                              className="w-full bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-4xl font-bold"
                              placeholder="Your answer"
                              value={answer}
                              onChange={(e) => setAnswer(e.target.value)}
                            />
                          </div>
                          {/* Submit Button */}
                          <div className="flex justify-center mb-8 relative group">
                            <button
                              onClick={handleSubmitthree}
                              className={`w-full py-3 rounded-lg shadow-md text-2xl font-bold transition-all duration-200 transform ${isSubmitDisabled3
                                  ? "bg-gray-500 text-gray-300 cursor-not-allowed"
                                  : "bg-blue-500 text-white hover:bg-blue-700 hover:scale-105"
                                }`}
                              disabled={isSubmitDisabled3}
                            >
                              Submit Answer
                            </button>
                            {isSubmitDisabled3 && (
                              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Answer already submitted
                              </div>
                            )}
                          </div>

                          {/* Show loading spinner */}
                          {showLoadingscore && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                          <div className="mt-6">
                            {evaluationSecondthree && (
                              <div className="text-3xl font-semibold text-gray-300">
                                {evaluationSecondthree.split('\n').map((line, index) => (
                                  <p
                                    key={index}
                                    className={line.startsWith(' - ') ? 'text-yellow-500' : ''}
                                  >
                                    {line}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                          {evaluationSecondthree && (
                            <div className="mt-6"> {/* Adding margin-top for spacing */}
                              <button
                                onClick={nextEvaluationSecondthree}
                                className="bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-700 transition-transform transform hover:scale-105 duration-200 font-bold"
                              >
                                Next
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      {/* Display fourth question after submitting the third */}
                      {showNextevaluationsecondThree && (
                        <div className="mt-2 max-w-5xl mx-auto p-8 rounded-lg shadow-xl">
                          <div className="mb-4">
                            <p className="text-5xl font-semibold text-white mb-4">{questions[3].question}</p>
                            <input
                              type="number"
                              className="w-full bg-gray-700 text-white px-6 py-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 text-4xl font-bold"
                              placeholder="Your answer"
                              value={answer}
                              onChange={(e) => setAnswer(e.target.value)}
                            />
                          </div>
                          {/* Submit Button */}
                          <div className="flex justify-center mb-8 relative group">
                            <button
                              onClick={handleSubmitfour}
                              className={`w-full py-3 rounded-lg shadow-md text-2xl font-bold transition-all duration-200 transform ${isSubmitDisabled4
                                  ? "bg-gray-500 text-gray-300 cursor-not-allowed"
                                  : "bg-blue-500 text-white hover:bg-blue-700 hover:scale-105"
                                }`}
                              disabled={isSubmitDisabled4}
                            >
                              Submit Answer
                            </button>
                            {isSubmitDisabled4 && (
                              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Answer already submitted
                              </div>
                            )}
                          </div>

                          {/* Show loading spinner */}
                          {showLoadingqns && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                          <div className="mt-6">
                            {evaluationSecondfour && (
                              <div className="text-3xl font-semibold text-gray-300">
                                {evaluationSecondfour.split('\n').map((line, index) => (
                                  <p
                                    key={index}
                                    className={line.startsWith(' - ') ? 'text-yellow-500' : ''}
                                  >
                                    {line}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {evaluationSecondfour && (
                        <div className="mt-4">
                          {allQuestionsAnswered && !isScoreVisible && (
                            <div className="mt-4 relative group">
                              <button
                                onClick={handleViewScoreAndSubmit}
                                className={`bg-blue-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${isScoreVisible1 ? "bg-gray-500 text-gray-300 cursor-not-allowed" : "hover:bg-blue-700"
                                  }`}
                                disabled={isScoreVisible1}
                              >
                                View Score
                              </button>
                              {isScoreVisible1 && (
                                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                  Score already viewed
                                </div>
                              )}
                            </div>

                          )}
                          <br />
                          {showLoadingscore && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing Feedack, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                          <br />
                          {isScoreVisible && (
                            <div className="flex justify-center items-center">
                              <div className="text-center border-4 border-white-500 bg-gray-00 p-6 rounded-lg shadow-lg">
                                <p className="text-3xl font-bold font-jp text-yellow-500/90 italic">
                                  {`You have answered ${score} out of ${totalQuestions} questions correctly!`}
                                </p>
                                <p>
                                  <span className="text-2xl font-bold text-white/90 italic">Your percentage: </span>
                                  <span className="text-3xl font-bold font-jp text-green-500/90 italic">
                                    {`${((score / questions.length) * 100).toFixed(2)}%`}
                                  </span>
                                </p>
                                <div className="mt-6">
                                  <div className="mt-4 text-3xl font-bold text-gray-300">
                                    {adviceMessage && <p>{adviceMessage}</p>}
                                  </div>
                                </div>
                                {/* Finish Button Below */}
                                <div className="mt-6 relative group">
                                  <button
                                    onClick={handleFinishsumary}
                                    className={`bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${isFinishButtonDisabled4 ? "bg-gray-500 cursor-not-allowed" : "hover:bg-green-700"
                                      }`}
                                    disabled={isFinishButtonDisabled4}
                                  >
                                    Finish
                                  </button>
                                  {isFinishButtonDisabled4 && (
                                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                      Already finished
                                    </div>
                                  )}
                                </div>

                              </div>
                            </div>
                          )}
                          {showLoadingBeforeEq2 && (
                            <div className="mt-6 flex justify-center items-center">
                              <div className="flex items-center space-x-4">
                                {/* Text to indicate loading */}
                                <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                                {/* FontAwesome spinning icon */}
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                  className="text-indigo-500"  // Customize the color here
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* {msg.content.evaluation && (
                  <div className="mt-4 bg-gray-900 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2">Evaluation</h3>
                    <div className="text-3xl font-bold text-white-300">
                      {(() => {
                        const lines = (displayedText.evaluation || "").split("\n");
                        return lines.map((line, index) => (
                          <p key={index} className="mb-2">{line}</p>
                        ));
                      })()}
                    </div>
                    <div className="mt-6 bg-gray-900 px-3 py-2 rounded-md shadow-sm text-center">
                      <p className="text-lg font-bold text-gray-300">
                        After submitting 4 answers, please click on the
                        <span className="text-green-600 font-semibold"> "Finish" </span> button above to proceed.
                      </p>
                    </div>
                  </div>
                )} */}

                {msg.content.summary && (
                  <div className="mt-4  px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2">Summary</h3>
                    <div className="text-4xl font-bold text-white-200">
                      {(() => {
                        // Safely access and split 'summary' into lines from the backend text
                        const lines = (displayedText.summary || "").split("\n");

                        // Process each line to handle styles and line breaks
                        return lines.map((line, index) => {
                          // Add a larger line break after "What Have We Learned Today?"
                          if (line.trim() === "What Have We Learned Today?") {
                            return (
                              <React.Fragment key={index}>
                                <p className="mb-4">{line}</p>
                                <br /> {/* Add a line break */}
                              </React.Fragment>
                            );
                          }

                          // Apply specific styles for numbered points
                          if (line.startsWith("1.")) {
                            return (
                              <p key={index} className="mb-2 text-yellow-400">
                                {line}
                              </p>
                            );
                          }
                          if (line.startsWith("2.")) {
                            return (
                              <p key={index} className="mb-2 text-yellow-400">
                                {line}
                              </p>
                            );
                          }
                          if (line.startsWith("3.")) {
                            return (
                              <p key={index} className="mb-2 text-yellow-400">
                                {line}
                              </p>
                            );
                          }

                          // Default white styling for other lines
                          return (
                            <p key={index} className="mb-2">
                              {line}
                            </p>
                          );
                        });
                      })()}
                    </div>

                    {isSummary && (
                      <div className="mt-6 relative group">
                        <button
                          onClick={handleFinish}
                          className={`bg-green-500 text-2xl text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105 duration-200 font-bold ${isFinishButtonDisabled5 ? "bg-gray-500 cursor-not-allowed" : "hover:bg-green-700"
                            }`}
                          disabled={isFinishButtonDisabled5}
                        >
                          Finish
                        </button>
                        {isFinishButtonDisabled5 && (
                          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 text-sm bg-gray-800 text-white rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            Already finished
                          </div>
                        )}
                      </div>

                    )}
                    {showLoadingBeforeEq2 && (
                      <div className="mt-6 flex justify-center items-center">
                        <div className="flex items-center space-x-4">
                          {/* Text to indicate loading */}
                          <span className="text-3xl font-semibold text-white">Processing, please wait...</span>
                          {/* FontAwesome spinning icon */}
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            className="text-indigo-500"  // Customize the color here
                          />
                        </div>
                      </div>
                    )}

                  </div>
                )}

                {msg.content.conclusion && (
                  <div className="mt-4 border-4 border-white-500 px-8 py-8 rounded-lg shadow-sm">
                    <h3 className="text-2xl font-bold text-gray-300 underline mb-2">Conclusion</h3>
                    <div className="text-4xl font-bold text-white-200">
                      {(() => {
                        // Safely access and split 'conclusion' into lines from the animated text
                        const lines = (displayedText.conclusion || "").split("\n");

                        // Loop through each line and render it with styles
                        return lines.map((line, index) => (
                          <p key={index} className="mb-2">
                            {line}
                          </p>
                        ));
                      })()}
                    </div>
                    {isConclusion && (
                      <div className="mt-8 flex flex-col items-center space-y-6">
                        <div className="text-center">
                          <h3 className="text-3xl font-bold text-gray-300 mb-4">
                            Thank you for completing this chapter!
                          </h3>
                          <p className="text-xl text-gray-400">
                            You can download the summary notes for future reference.
                          </p>
                        </div>
                        {/* Add the PDF download button */}
                        <PdfDownloadButton />
                      </div>
                    )}
                  </div>
                )}

              </div>
            ))}
          </div>
        )}
      </div>
    </Html>
  );
};

export default StMessListTM;