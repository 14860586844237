import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGraduate, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../config.js";
import axios from "axios";

export const UserTypeSelection = ({ onUserTypeSelect }) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [generatedOtp, setGeneratedOtp] = useState(null); // Store OTP from backend

  // State to track the selected user type
  const [selectedUserType, setSelectedUserType] = useState("");

  // Check Local Storage for existing OTP and email on reload
  useEffect(() => {
    const storedOtp = localStorage.getItem("otp");
    const storedEmail = localStorage.getItem("email");
    if (storedOtp && storedEmail) {
      setIsOtpVerified(true);
      setEmail(storedEmail);
    }
  }, []);

  const handleSendOtp = async () => {
    if (!email) {
      alert("Please enter your email");
      return;
    }

    setIsSendingOtp(true);
    try {
      const response = await axios.post(`${API_URL}/sendotp/`, { email });

      if (response.data.success) {
        setGeneratedOtp(response.data.otp); // Save OTP to state
        setIsOtpSent(true);
        alert("OTP sent to your email.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("Failed to send OTP. Please try again.");
    } finally {
      setIsSendingOtp(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      setErrorMessage("Please enter the OTP.");
      return;
    }

    // Compare generated OTP and user-entered OTP
    if (otp === generatedOtp.toString()) {
      setIsOtpVerified(true);
      localStorage.setItem("otp", otp); // Save OTP in localStorage
      localStorage.setItem("email", email); // Save email in localStorage
      alert("OTP verified successfully!");
    } else {
      alert("Invalid OTP. Please try again.");
    }
  };

  const handleRequestAccess = async (userType) => {
    if (!userType) {
      alert("Please verify your OTP before selecting a user type.");
      return;
    }

    setSelectedUserType(userType); // Set the selected user type to disable buttons
    try {
      const response = await axios.post(`${API_URL}/requestaccess/`, {
        email,
        user_type: userType,
      });

      onUserTypeSelect(userType);
      alert(response.data.message);
    } catch (error) {
      console.error("Error requesting access:", error);
      alert("Failed to request access. Please try again.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("otp"); // Clear OTP from localStorage
    localStorage.removeItem("email"); // Clear email from localStorage
    setIsOtpVerified(false);
    setIsOtpSent(false);
    setOtp("");
    setEmail("");
    setSelectedUserType("");
    alert("You have been logged out.");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-black via-gray-600 to-white">
      <div className="bg-white p-12 rounded-3xl shadow-2xl max-w-lg w-full text-center transform transition-all duration-500 hover:scale-105 hover:shadow-3xl">
        {!isOtpVerified ? (
          <>
            <h1 className="text-4xl font-extrabold mb-8 text-gray-700 animate-fade-in">
              Enter your email
            </h1>

            {/* Email Input */}
            <div className="relative flex items-center border-b border-gray-300 py-2 focus-within:border-gray-700 transition-colors duration-300">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="w-full p-2 text-lg bg-transparent placeholder-gray-600 text-gray-900 focus:outline-none focus:text-gray-700 transition-all duration-300"
              />
            </div>

            <button
              onClick={handleSendOtp}
              disabled={isSendingOtp}
              className={`w-full py-4 rounded-lg font-bold text-lg uppercase transform hover:scale-105 transition-transform duration-300 mt-8 ${
                isSendingOtp
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-gray-900 text-white hover:bg-gray-700"
              }`}
            >
              {isSendingOtp ? "Sending OTP..." : "Send OTP"}
            </button>

            {isOtpSent && (
              <>
                <div className="relative flex items-center border-b border-gray-300 py-2 focus-within:border-gray-700 transition-colors duration-300 mt-8">
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                    className="w-full p-2 text-lg bg-transparent placeholder-gray-600 text-gray-900 focus:outline-none focus:text-gray-700 transition-all duration-300"
                  />
                </div>
                <button
                  onClick={handleVerifyOtp}
                  className="w-full py-4 rounded-lg font-bold text-lg uppercase transform hover:scale-105 transition-transform duration-300 mt-8 bg-gray-900 text-white hover:bg-gray-700"
                >
                  Verify OTP
                </button>
              </>
            )}

            {errorMessage && (
              <div className="text-red-500 mt-4 text-sm">{errorMessage}</div>
            )}
          </>
        ) : (
          <>
            <h1 className="text-4xl font-extrabold mb-8 text-gray-700 animate-fade-in">
              Select your user type
            </h1>

            <div className="space-y-8">
              <button
                onClick={() => handleRequestAccess("student")}
                disabled={!!selectedUserType}
                className={`w-full py-4 rounded-lg font-bold text-lg uppercase transform hover:scale-105 transition-transform duration-300 flex items-center justify-center ${
                  selectedUserType
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-gray-200 text-gray-900 hover:bg-gray-300"
                }`}
              >
                <FontAwesomeIcon
                  icon={faUserGraduate}
                  className="text-gray-700 hover:text-white text-3xl mr-4"
                />
                K-12 Students
              </button>

              <button
                onClick={() => handleRequestAccess("non-student")}
                disabled={!!selectedUserType}
                className={`w-full py-4 rounded-lg font-bold text-lg uppercase transform hover:scale-105 transition-transform duration-300 flex items-center justify-center ${
                  selectedUserType
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-gray-200 text-gray-900 hover:bg-gray-300"
                }`}
              >
                <FontAwesomeIcon
                  icon={faUserTie}
                  className="text-gray-700 hover:text-white text-3xl mr-4"
                />
                Non-Students
              </button>
              <button
                onClick={handleLogout}
                className="w-full py-4 mt-8 bg-red-500 font-bold text-white text-xl rounded-lg"
              >
                Logout
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
